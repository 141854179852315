<template>
    <div class="main">
        <div>{{ result }}</div>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'

export default {
    data() {
        return {
            // 1: 解除深度禁言, 2: 添加关键词至白名单, 3: 封禁用户, 4: 重发消息 5: 解析ip
            type: '0',
            result: '',
        }
    },
    methods: {
        initData() {
            if (this.type == '1') {
                let userId = this.$route.query['user_id']

                doRequest('/app/shadowOperate', {
                    user_id: userId,
                    type: 2,
                    result: 0,
                }, this.$route.query).then(() => {
                    this.result = '深度禁言已解除'
                })
            } else if (this.type == '2') {
                let keyword = this.$route.query['keyword']

                doRequest('/app/makeKeywordSafe', {
                    word: keyword,
                }, this.$route.query).then(() => {
                    this.result = '已添加关键词白名单'
                })

            } else if (this.type == '3') {
                let userId = this.$route.query['user_id']

                doRequest('/app/forbidUser', {
                    user_id: userId,
                }, this.$route.query).then(() => {
                    this.result = '用户已被封禁'
                })
            } else if (this.type == '4') {
                let from = this.$route.query['from']
                let to = this.$route.query['to']
                let msg = this.$route.query['msg']
                let request_id = this.$route.query['request_id']

                doRequest('/app/resendMsg', {
                    from,
                    to,
                    msg,
                    request_id,
                }, this.$route.query).then(() => {
                    this.result = '消息已补发'
                })
            } else if (this.type == '5') {
                let ip = this.$route.query['ip']

                doRequest('/app/analyseIp', {
                    ip
                }, this.$route.query).then(res => {
                    this.result = res.country + ', ' + res.city + ', ' + res.org
                })
            } else {
                this.result = '错误的操作类型'
            }
        },
    },
    mounted() {
        this.type = this.$route.query['type']
        this.initData()
    }
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}
</style>